<template>
  <div id="featured">
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              <span class="header-etsy">&epsilon;</span>dliy Demo Videos
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />

    <v-layout :style="myStyle.layout"
              align-center
              justify-center
              row
              wrap
              :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        Edliy uses the power of visual interactivity to teach science and math concepts through visual storytelling.<br>
        Below are some of our demo videos to help you get started with Edliy.
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald">Math Demo | Probability Theory</span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp, 'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Eienstein once famously said &mdash; "God doesn't play dice".<br><br>
          But if the god did roll a pair of dice, can you tell what sum is most probable? <br><br>
          Explore our visually interactive module on theory of probability to learn more.
          <br>
          <a v-scroll-to="'#library'" class="btnLeft mt-3 mb-3">Start Learning!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
        justify-center
        align-center
      >
        <v-responsive class="mt-3"
                      :aspect-ratio="1"
                      align="center"
                      justify="center"
        >
          <iframe
            style="border:1.5px solid black; border-radius:7.5px;"
            src="https://www.youtube.com/embed/SL3efH5xk7Y"
            width="80%"
            height="80%"
          />
        </v-responsive>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <v-layout
      :style="myStyle.layout"
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Chemistry Demo | Boyle's Law </span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Why helium balloons grow bigger as they ascend? <br><br>
          Why astronauts need to wear a spacesuit? <br><br>
          Why soda fizzles when you open a can of soda?
          The answer is &mdash; Boyle's law.
          <br>
          <a v-scroll-to="'#library'" class="btnLeft mt-3 mb-3">Try It Yourself!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-responsive class="mt-3"
                      align="center"
                      justify="center"
                      :aspect-ratio="1"
        >
          <iframe
            style="border:1.5px solid black; border-radius:7.5px;"
            src="https://www.youtube.com/embed/Po9_hZiNj44"
            width="80%"
            height="80%"
          />
        </v-responsive>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <v-layout
      :style="myStyle.layout"
      align-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div class="font-weight-medium" :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}">
          <span style="font-family:Oswald">Physics Demo | Equilibrium of Forces </span>
        </div>
        <div style="margin-top: 10px" />
        <div class="font-weight-light subheader-content"
             :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          What keeps the planets in orbit around Sun? <br> <br> What holds the neutron, proton and electron together inside an atom? <br><br>
          What makes a car speed and then stop? <br><br> We may not see them &mdash; but we are always surrounded by forces.
          <br>
          <a v-scroll-to="'#library'" class="btnLeft mt-3 mb-3"> See Our Apps in Action!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-responsive class="mt-3"
                      aspect-ratio="1"
                      align="center"
                      justify="center"
        >
          <iframe
            style="border:1.5px solid black; border-radius:7.5px;"
            src="https://www.youtube.com/embed/BmdUHo5CpcE"
            width="80%"
            height="80%"
          />
        </v-responsive>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'Dash1',
  props: {
    myStyle: {
      type: Object,
        default: () => {}
    },
  }
}
</script>
