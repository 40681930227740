// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeDash,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeErase,
    placePlay,
    placePause
} from '../../common/edliy_utils-geometric';
import {
    createSpace,
    placeWhite,
    placeBCircles,
    placeCircles,
    placeChecks,
    placeCross,
    placeExclaim,
    placeFingers,
    writeHTMLText,
    placeRedo,
    placeStartOver,
    placeBoldText,
    placeTest
} from '../../common/Utils';
const Boxes = {
    box1a: function () {
      JXG.Options.board.minimizeReflow = 'none';
      JXG.Options.text.highlight=false;
      JXG.Options.text.fixed=true;
      JXG.Options.image.highlight=false;
      JXG.Options.text.cssDefaultStyle='fontFamily:Oswald';
      JXG.Options.line.highlight=false;
      JXG.Options.arc.highlight=false;
      JXG.Options.point.highlight=false;
      JXG.Options.point.showInfoBox=false;
      var brd2 = JXG.JSXGraph.initBoard('jxgbox1a',{boundingbox: [-1, 10.5, 14., -4.5],
          keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
      makeResponsive(brd2);
      brd2.options.layer['line'] =6;
      brd2.options.layer['segment'] =6;
      brd2.options.layer['point'] =6;
      brd2.options.layer['polygon'] =2;
      brd2.options.layer['image'] =8;
        ////////////////////////////////////////////////////
      //brd2.create('text', [5, 10, '<b>Projectile Motion</b>'], {fontSize:function(){return 32*brd2.canvasHeight/800}});
      placeTitle(brd2, 'Projectile Motion');
      placeLogo(brd2);
      var play = placePlay(brd2,'left');
      var eraser = placeErase(brd2);
      //
      var circc=brd2.create('arc', [[0,0],[5, 0], [0.00,5]],{visible:true, strokeWidth:0.5, strokeColor:'black', dash:1, center:{visible:false}, radiuspoint:{visible:false}, anglepoint:{visible:false}});
      //
      var lev =brd2.create('glider', [5, 5, circc], {face:'circle', size:3, name:'Launch Angle (Drag Me!)', strokeColor:'black', label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});

      brd2.create('polygon', [[-1, -0], [-1, -5], [22, -5], [22, -0]],{vertices:{visible:false, fixed:true}, withLines:false, fillColor:'grey'});
      //
    //  var vel = brd2.create('slider', [[0, -2],[4, -2],[5, 8, 11.5]], {baseline:{strokeWidth:7, lineCap:'round', strokeColor:'grey'},highline:{strokeWidth:7, strokeColor:'black', lineCap:'round'}, name:'Launch Speed',size:function(){return 10*brd2.canvasHeight/800},face:'circle', fillColor:'grey',strokeColor:'black', withTicks:false, label:{fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});

      var vel = placeSlider(brd2, 6, -1, 8, 9.5, 11, 3, 'Launch Speed');
      vel.setAttribute({unitLabel:'m/s'})
      //
      var ang =brd2.create('angle', [[1,0], [0,0], lev],{visible:false});
      //
      var ball =brd2.create('point', [0, 0.0], {name:'', size:1, trace:true, fixed:true});
      //
      brd2.create('image', ['/assets/player.svg', [-0.25, -1.4],[2,2]],{fixed:true, shadow:false});
      //
      var x =0; var y=0; var tt=0; var dt=0.015; var i=0; var j=0;
      var run =function(){
      if(i==0){
      ball.clearTrace();}
      i =1;
      if(x<=vel.Value()*vel.Value()*Math.sin(2*ang.Value())/9.8 && j>0){
      tt +=dt;
      x = vel.Value()*Math.cos(ang.Value())*tt;
      y = vel.Value()*Math.sin(ang.Value())*tt -0.5*9.8*tt*tt;}
      ball.moveTo([x,y]);
      if(x<=vel.Value()*vel.Value()*Math.sin(2*ang.Value())/9.8 && j>0){
      setTimeout(run, 0);}
      else{return;}
          }
      play.on('down', function(){x=0; y=0.0; tt=0; i=0; j=1; run();});
      eraser.on('down', function(){x=0; y=0.0; tt=0; i=0; j=0; ball.clearTrace(); ball.moveTo([0,0])});
      vel.on('down', function(){x=0; y=0; j=0});
      lev.on('down', function(){x=0; y=0; j=0});
      //
      //brd2.create('curve',[function(t){return vel.Value()*Math.cos(ang.Value())*t},
      //					function(t){return vel.Value()*Math.sin(ang.Value())*t -0.5*9.8*t*t},
      // 0, function(){return ball.X()/vel.Value()/Math.cos(ang.Value())}],{strokeWidth:1, dash:1,  strokeColor:'black', visible:true});
      //
      brd2.create('image', ['/assets/ball.svg',
      [function(){return x-0.25}, function(){return lev.Y()*x/lev.X()-0.5*9.8*x*x/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value()))-0.25}],[0.5,0.5]],{highlight:false});
        //
      brd2.create('arrow', [[0,0], lev],{dash:1, strokeColor:'grey'});
      //
      brd2.create('segment', [[0.0,0], [function(){return x;}, 0.0]],{strokeColor:'black', strokeWidth:1, dash:1});
      //
      brd2.create('segment', [[function(){return vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6}, 0.0], [function(){return vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6}, function(){return lev.Y()*Math.min(x, vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/lev.X()-0.5*9.8*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value()))}]],{strokeColor:'black', strokeWidth:1, dash:1});
      //
      brd2.create('text', [function(){return x*0.5}, -.25,  function(){return (x).toFixed(2)+' m'}], {fixed:true, fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

      brd2.create('text', [function(){return vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6 +0.15}, function(){return 0.5*(lev.Y()*Math.min(x, vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/lev.X()-0.5*9.8*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value())));}, function(){return (lev.Y()*Math.min(x, vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/lev.X()-0.5*9.8*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)*Math.min(x,vel.Value()*vel.Value()*Math.sin(2*ang.Value())/19.6)/(vel.Value()*Math.cos(ang.Value()))/(vel.Value()*Math.cos(ang.Value()))).toFixed(2) +' m'}], {fixed:true, fontSize:function(){return 12*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
       //
      brd2.create('text', [1, 8.5, '1. Select a launch angle. 2. Select a launch speed. 3. Tap on the play button to throw.'], {fixed:true, fontSize:function(){return 20*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
      //
      brd2.create('text', [2, -1, function(){return 'Launch Angle =' + (180*ang.Value()/Math.PI).toFixed(1) + '(<sup>o </sup>)'}], {fixed:true, fontSize:function(){return 18*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    },
//
    box2a: function () {
		var brd1 = JXG.JSXGraph.initBoard('jxgbox2a',{boundingbox: [0, 6, 12, -6],
      keepaspectratio:true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false,pan:{enabled:false}, zoom:{enabled:false}});
		/*var resize = function () {
          brd1.resizeContainer(brd1.containerObj.clientWidth, brd1.containerObj.clientHeight, true);
          brd1.fullUpdate();
          };
		window.onresize = resize;*/
		brd1.suspendUpdate();
    placeLogo(brd1);
    makeResponsive(brd1);
  //  var shuffle =brd1.create('image', ['/assets/shuffle.svg', [10, -4], [1,1]],{highlight:false, opacity:1, fixed:true});
    var shuffle =placeShuffle(brd1);
    var erase = placeErase(brd1);
    //var tryMe = brd1.create('image', ['/assets/test.svg', [2., -4], [1,1]],{highlight:false, opacity:1, fixed:true});
    var list=['CH_4', 'C_2H_6', 'C_3H_8','C_4H_1_0'];
    var i=0;
    shuffle.setLabel('Tap to Shuffle')
    shuffle.label.setAttribute({visible:false, offset:[-15, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd1.canvasHeight/800}});
    shuffle.on('over', function () {this.label.setAttribute({visible:true});});
    shuffle.on('out', function () {this.label.setAttribute({visible:false});});
    //erase.on('down', function(){clearInputFields(brd1)});
    erase.setLabel('Tap to Erase')
    erase.label.setAttribute({visible:false, offset:[-15, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd1.canvasHeight/800}});
    erase.on('over', function () {this.label.setAttribute({visible:true});});
    erase.on('out', function () {this.label.setAttribute({visible:false});});
/////////////////////////////////////////////////////////////////////////////////////////////
    /*tryMe.setLabel('Tap to Test Your Answer')
    tryMe.label.setAttribute({visible:false, offset:[-15, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 12*brd1.canvasHeight/800}});
    tryMe.on('over', function () {this.label.setAttribute({visible:true});});
    tryMe.on('out', function () {this.label.setAttribute({visible:false});});*/
////////////////////////////////////////////////////////////////////////////////////////////////////////////
		brd1.create('text', [6, 5., function(){return 'Oxidation of '+ list[i.valueOf()]}], {highlight:false, anchorX:'middle', highlight:false, fixed:true, fontSize:function(){return 32*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold'})
////////////////////
    var Cmethane = brd1.create('input', [0.85, 3.35, '0', ' '], {cssStyle:'width:8.3333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});

    //placeTitle(brd1, function(){return 'Oxidation of '+ list[i.valueOf()]});
		brd1.create('text', [1.85, 3.35, function(){return '&nbsp;' + list[i.valueOf()]+' +'}], {highlight:false, anchorX:'left', fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    //////////////////////////////////////////
		var Coxy = brd1.create('input', [3, 3.35, '0', ' '], {cssStyle: 'width:8.33333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
		brd1.create('text', [4, 3.35, '&nbsp; O_2'], {highlight:false, fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('image',['/assets/random.svg', [5.75, 2.7], [1., 1]],{rotate:45, highlight:false, fixed:true});
    //brd1.create('arrow', [[5, 3.35],[6, 3.35]], {highlight:false,strokeColor:'black', strokeWidth:function(){return 5*brd1.canvasHeight/800}, fixed:true});
		var Ccotwo = brd1.create('input', [7, 3.35, '0', ' '], {cssStyle: 'width:8.333333%', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
		brd1.create('text', [8, 3.35, '&nbsp; CO_2 +'], {highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		var Cwater = brd1.create('input', [9, 3.35, '0', ' '], {cssStyle: 'width:8.3333333333%;', fontSize:function(){return 24*brd1.canvasHeight/800}, fixed:true,parse:true});
		brd1.create('text', [10, 3.35, '&nbsp; H_2O'], {highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//var dsa =brd.create('slider', [[2, 4],[6, 4], [1, 2, 8]]);
//Mass Budget
		brd1.create('line', [[1, 2.],[5, 2.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
		brd1.create('line', [[1, 2.05],[5, 2.05]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
		brd1.create('text', [2.5, 1.5, '<b> REACTANT SIDE</b>'], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('text', [8.5, 1.5, '<b> PRODUCT SIDE </b>'], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('line', [[1, 1.],[5, 1.]], {highlight:false,strokeColor:'grey', strokeWidth:1,fixed:true});
		brd1.create('line', [[1, 1.05],[5, 1.05]], {highlight:false,strokeColor:'grey', strokeWidth:1, fixed:true});
// REACTION SIDE
		brd1.create('text', [2.5, 0.5, function(){return (i.valueOf()+1)*Cmethane.Value() + ' Moles of C'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:	'fontFamily:Oswald;'});
    brd1.create('image', ['/assets/check.svg', [9.6, 0.1], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 1*Ccotwo.Value()==(i.valueOf()+1)*Cmethane.Value() && 1*Cmethane.Value()>0} });
    //brd1.create('image', ['/assets/cross.svg', [9.6, 0.1], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 1*Ccotwo.Value()!=(i.valueOf()+1)*Cmethane.Value() && 1*Cmethane.Value()>0} });
    brd1.create('text', [2.5, -0.5, function(){return 2* Coxy.Value() + ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('image', ['/assets/check.svg', [9.6, -0.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 2*Coxy.Value() == 1*Cwater.Value()+2*Ccotwo.Value()&& 1*Coxy.Value()>0} });

    brd1.create('text', [2.5, -1.5, function(){return (2*(i.valueOf()+1)+2)*Cmethane.Value() + ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
    brd1.create('image', ['/assets/check.svg', [9.6, -1.9], [0.8,0.8]],{highlight:false, opacity:1, fixed:true, visible:function(){return 2*Cwater.Value() == (2*(i.valueOf()+1)+2)*Cmethane.Value()&& 1*Cwater.Value()>0} });
// PRODUCT SIDE
		brd1.create('text', [8.5, 0.5, function(){return 1*Ccotwo.Value() + ' Moles of C'}], {anchorX:'middle',highlight:false, anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('text', [8.5, -0.5, function(){return 1*Cwater.Value()*1+2*Ccotwo.Value()+ ' Moles of O'}], {anchorX:'middle',highlight:false,anchorY:'middle',fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('text', [8.5, -1.5, function(){return 2* Cwater.Value() + ' Moles of H'}], {anchorX:'middle',highlight:false,anchorY:'middle', fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		brd1.create('line', [[1, -2],[5, -2]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true});
		brd1.create('line', [[1, -1.95],[5, -1.95]], {highlight:false, strokeColor:'grey', strokeWidth:1, fixed:true});
		brd1.create('text', [6, -2.5,'Balanced or Not Balanced?'],{anchorX:'middle', anchorY:'middle', highlight:false,fixed:true, fontSize:function(){return 24*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
		var res = brd1.create('text', [6, -3.5, function(){if(1*Ccotwo.Value()==(i.valueOf()+1)*Cmethane.Value() && 2*Coxy.Value() == 1*Cwater.Value()+2*Ccotwo.Value() && 2*Cwater.Value() == (2*(i.valueOf()+1)+2)*Cmethane.Value()){return 'The Equation is Now Balanced'} else {return 'The Equation is Not Yet Balanced'}}], {anchorX:'middle', anchorY:'middle', highlight:false,fontSize:function(){if(Cmethane.Value()==0){return 0} else{return 24*brd1.canvasHeight/800}}, cssStyle:'fontFamily:Oswald;', fixed:true});
    //tryMe.on('down', function(){res.setAttribute({visible:true})});
    shuffle.on('down', function(){return i=Math.floor(Math.random()*4)});
    shuffle.on('down', function(){
    //  res.setAttribute({visible:false});
      let magicGraph = brd1.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "0";}
    });
    erase.on('down', function(){
  //    res.setAttribute({visible:false});
      let magicGraph = brd1.containerObj;
      let inputFields = magicGraph.querySelectorAll("input");
          for (let inputField of inputFields) {
                inputField.value = "0";}
    });

		brd1.unsuspendUpdate();
	},
box3a: function () {
	var brd4 = JXG.JSXGraph.initBoard('jxgbox3a',{boundingbox: [-4, 9.5, 8, -2.5], keepaspectratio: true, axis:false, ticks:{visible:false},
	grid:true, showCopyright:false, showNavigation:false,
	pan:{enabled:false}, zoom:{enabled:false}});
	/*var resize = function () {
      brd4.resizeContainer(brd4.containerObj.clientWidth, brd4.containerObj.clientHeight, true);
      brd4.fullUpdate();
      };
	window.onresize = resize;*/
	brd4.suspendUpdate();
  placeTitle(brd4, 'Square Formula', '');
  placeLogo(brd4);
  makeResponsive(brd4);
  //brd4.create('text', [2, 8, 'Square Formula:(a+b)^2 = a^2 + b^2 + 2ab'],
  //{anchorX:'middle', fixed:true, cssStyle:'fontFamily:Oswald;fontWeight:bold', fontSize:function(){return Math.round(32*brd4.canvasWidth/800.)}});
  var i =0;
	brd4.create('text', [2, 7.5, 'Area of Square ABCD = (a+b)^2'],{anchorX:'middle',anchorY:'middle', visible:function(){if(i<=1){return true} else{return false}}, fixed: true, strokeColor:'black',  fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
	var subhead2 = brd4.create('text', [2, 6.5, 'Area ABCD = a^2 + 2ab + b^2'],{visible:false, anchorX:'middle', anchorY:'middle', fixed: true, strokeColor:'black', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
	var subhead3=brd4.create('text', [2.,7.5, '(a+b)^2 = 4^2 = 16 bins'],{visible:false, anchorX:'middle', anchorY:'middle', fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//the sides of the square measure
	var total = 4;
//Extreme ponits of the square
	var extreme1 = brd4.create('point', [0,0],{fixed:true, name:'A', size:0, label: {anchorX:'middle', highlight:false, offset:[-10, -10], fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
	var extreme2 = brd4.create('point', [total,0],{fixed:true, name:'B', size:0, label: {anchorX:'middle', highlight:false, offset:[10, -10], fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
	var extreme3 = brd4.create('point', [total, total],{fixed:true, name:'C', size:0, label: {anchorX:'middle', highlight:false, offset:[10, 10], fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
	var extreme4 = brd4.create('point', [0,total],{fixed:true, name:'D', size:0, label: {anchorX:'middle', highlight:false, offset:[-10, 10],fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});
//Black borders of the square
  var border1 = brd4.create('segment', [extreme1, extreme2], {fixed: true, strokeColor: 'black'});
  var border2 = brd4.create('segment', [extreme2, extreme3], {fixed: true, strokeColor: 'black'});
  var border3 = brd4.create('segment', [extreme3, extreme4], {fixed: true, strokeColor: 'black'});
  var border4 = brd4.create('segment', [extreme1, extreme4], {fixed: true, strokeColor: 'black'});
//Interior points on the borders
var dragMe =brd4.create('glider', [4,total, border3], {fillColor:'red', highlight:false, face:'square', size:3, name:'Drag Me!', snapToGrid:function(){if(i==2){return true}else{return false}}, label: {offset: [0,20], CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/800.)} }, strokeColor: 'black', visible:false});
var int1 =brd4.create('point', [function(){return dragMe.X()}, 0], {highlight:false, face:'square', size:3, name:'',strokeColor: 'black', fillColor: '#e1e1e1', visible:false});
var int2 =brd4.create('point', [total,function() {return  total - dragMe.X()}], {highlight:false, face:'square', size:3,strokeColor: 'black', fillColor: '#e1e1e1', name:'', visible:false});
var int4 =brd4.create('point', [0,function() {return total - dragMe.X()}], {highlight:false, face:'square', size:3, name:'', strokeColor: 'black', fillColor: '#e1e1e1',visible:false});
//Interior point
var interiorPoint = brd4.create('point', [function(){return dragMe.X()},function(){return total - dragMe.X()}],{face:'o' , name:'pp', size:2, strokeColor: 'black', fillColor: 'grey', withLabel:false, fixed:false, visible:false});
//
//Lines on the inside
var l1 = brd4.create('segment', [interiorPoint, int1], {fixed: false, strokeColor: 'black'});
var l2 = brd4.create('segment', [interiorPoint, int2], {fixed: false, strokeColor: 'black'});
var l3 = brd4.create('segment', [interiorPoint, dragMe], {fixed: false, strokeColor: 'black'});
var l4 = brd4.create('segment', [interiorPoint, int4], {fixed: false, strokeColor: 'black'});
//
//Polygons
brd4.create('polygon', [extreme1, int1, interiorPoint, int4],{highlight:false, fixed:true, strokeWidth:2, fillColor:'#ff5935', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});
brd4.create('polygon', [int1, extreme2, int2, interiorPoint],{highlight:false,fixed:true, strokeWidth:2, fillColor:'#6691ff', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});
brd4.create('polygon', [interiorPoint, int2, extreme3, dragMe],{highlight:false,fixed:true, strokeWidth:2, fillColor:'#ff5935', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});
brd4.create('polygon', [int4, interiorPoint, dragMe, extreme4],{highlight:false,fixed:true, strokeWidth:2, fillColor:'#95DD43', fillOpacity:0.25, borders:{fixed:true, strokeColor:'blue'},visible: true});
//
brd4.create('text', [-3, 3., function(){return 'a^2 = ' + (dragMe.X()*dragMe.X()).toFixed(0) + ' bins'}],{anchorY:'middle',visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
brd4.create('text', [-3, 2., function(){return 'b^2 = ' + ((4-dragMe.X())*(4-dragMe.X())).toFixed(0) + ' bins'}],{anchorY:'middle',visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
brd4.create('text', [-3, 1., function(){return 'ab = ' + ((dragMe.X())*(4-dragMe.X())).toFixed(0) + ' bins'}],{anchorY:'middle', visible:function(){if(i==2){return true} else{return false}}, fixed: true, strokeColor:'black', CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
var subhead4 = brd4.create('text', [ 2, 6.5, function(){return 'a^2 + b^2 + 2 x ab = ' + (dragMe.X()*dragMe.X()).toFixed(0)+'+'+((4-dragMe.X())*(4-dragMe.X())).toFixed(0)+'+2 x '+((dragMe.X())*(4-dragMe.X())).toFixed(0) + ' = 16 bins'}],{anchorX:'middle', anchorY:'middle',visible:false, fixed: true, strokeColor:'black',fontSize:function(){return Math.round(18*brd4.canvasWidth/500.)}});
//Arrows on border 2
var offset = 0.5;
brd4.create('arrow', [[total + offset, 0.0], [total + offset, total]],{highlight:false,strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});
brd4.create('arrow', [[0, total+offset], [total, total + offset]],{highlight:false,strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});
//
var arr2_1 = brd4.create('point', [function(){return total + offset},function(){return extreme2.Y()}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr2_2 = brd4.create('point', [function(){return total + offset},function(){return interiorPoint.Y()}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr2_3 = brd4.create('point', [function(){return total + offset},function(){return total}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
//
var arrBor2_1 = brd4.create ('arrow', [arr2_1, arr2_2], {highlight:false,visible:false, strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});
//var arrBor2_2 = brd4.create ('arrow', [arr2_2, arr2_1], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});
var arrBor2_3 = brd4.create ('arrow', [arr2_2, arr2_3], {highlight:false,visible:false, strokeColor:'black', strokeWidth: 1, fixed:true, firstArrow:true, lastArrow:true});
//var arrBor2_4 = brd4.create ('arrow', [arr2_3, arr2_2], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});
//Arrows on border 3
var arr3_1 = brd4.create('point', [function(){return extreme4.X()},function(){return total+offset}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr3_2 = brd4.create('point', [function(){return dragMe.X()},function(){return total+offset}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
var arr3_3 = brd4.create('point', [total,function(){return total+offset}],{face:'o' , name:'pp', size:2,withLabel:false, fixed:false, visible:false});
//
var arrBor3_1 = brd4.create ('arrow', [arr3_1, arr3_2], {highlight:false,visible: false, name:'h', strokeColor:'black', strokeWidth: 1,  firstArrow:true, lastArrow:true});
//var arrBor3_2 = brd4.create ('arrow', [arr3_2, arr3_1], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});
var arrBor3_3 = brd4.create ('arrow', [arr3_2, arr3_3], {highlight:false,visible: false, name:'h', strokeColor:'black', strokeWidth: 1,  firstArrow:true, lastArrow:true});
//var arrBor3_4 = brd4.create ('arrow', [arr3_3, arr3_2], {visible: false, name:'h', strokeColor:'black', strokeWidth: 1});

//REDO AND UNDO
var redo = brd4.create('image', ['/assets/redo.svg', [2.5,-1.5], [1,1]], {visible: true, fixed: true});
redo.setLabel('Next Step')
redo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/800.)}});
redo.on('over', function () {this.label.setAttribute({visible:true});});
redo.on('out', function () {this.label.setAttribute({visible:false});});
var undo = brd4.create('image', ['/assets/undo.svg', [0.5,-1.5], [1,1]], {visible: true, fixed: true});
undo.setLabel('Previous Step')
undo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(18*brd4.canvasWidth/800.)}});
undo.on('over', function () {this.label.setAttribute({visible:true});});
undo.on('out', function () {this.label.setAttribute({visible:false});});
var maxSteps = 2;

function updateStepTexts(){
    if (i<=0){

        dragMe.moveTo([4,4], 100);
        textInitial.setAttribute({visible: true});
	      text0_1.setAttribute({visible: true});
		    text0_2.setAttribute({visible: true});
        subhead2.setAttribute({visible:false});
        subhead3.setAttribute({visible:false});
        subhead4.setAttribute({visible:false});
    }
    if(i==1){

        dragMe.moveTo([3,4], 100);
        dragMe.setAttribute({visible:false});
        subhead2.setAttribute({visible:true});
        subhead3.setAttribute({visible:false});
        subhead4.setAttribute({visible:false});
        int1.setAttribute({visible:false});
		    int2.setAttribute({visible:false});
		    int4.setAttribute({visible:false});
        text1_1.setAttribute({visible: true});
        text1_2.setAttribute({visible: true});
        text1_3.setAttribute({visible: true});
        text1_4.setAttribute({visible: true});
        text1_5.setAttribute({visible: true});
        text1_6.setAttribute({visible: true});
        text1_7.setAttribute({visible: true});
        text1_8.setAttribute({visible: true});
        arrBor2_1.setAttribute({visible: true});
        //arrBor2_2.setAttribute({visible: true});
        arrBor2_3.setAttribute({visible: true});
       // arrBor2_4.setAttribute({visible: true});
        arrBor3_1.setAttribute({visible: true});
        //arrBor3_2.setAttribute({visible: true});
        arrBor3_3.setAttribute({visible: true});
        //arrBor3_4.setAttribute({visible: true});
		}
    if(i==2){

        dragMe.setAttribute({visible:true});
        int1.setAttribute({visible:true});
    		int2.setAttribute({visible:true});
        subhead2.setAttribute({visible:false});
        subhead3.setAttribute({visible:true});
        subhead4.setAttribute({visible:true});
	    	int4.setAttribute({visible:true});
        text2_1.setAttribute({visible: true});
        text2_2.setAttribute({visible: true});
        text2_3.setAttribute({visible: true});
        text2_4.setAttribute({visible: true});
        text2_5.setAttribute({visible: true});
        text2_6.setAttribute({visible: true});
        text2_7.setAttribute({visible: true});
        text2_8.setAttribute({visible: true});
        arrBor2_1.setAttribute({visible: true});
        //arrBor2_2.setAttribute({visible: true});
        arrBor2_3.setAttribute({visible: true});
        //arrBor2_4.setAttribute({visible: true});
        arrBor3_1.setAttribute({visible: true});
       // arrBor3_2.setAttribute({visible: true});
        arrBor3_3.setAttribute({visible: true});
        //arrBor3_4.setAttribute({visible: true});
    }
}
function eraseText(){
    textInitial.setAttribute({visible: false});
    dragMe.setAttribute({visible:false});
    int1.setAttribute({visible:false});
	int2.setAttribute({visible:false});
	int4.setAttribute({visible:false});
    text0_1.setAttribute({visible:false});
	text0_2.setAttribute({visible:false});
    text1_1.setAttribute({visible: false});
    text1_2.setAttribute({visible: false});
    text1_3.setAttribute({visible: false});
    text1_4.setAttribute({visible: false});
    text1_5.setAttribute({visible: false});
    text1_6.setAttribute({visible: false});
    text1_7.setAttribute({visible: false});
    text1_8.setAttribute({visible: false});
    text2_1.setAttribute({visible: false});
    text2_2.setAttribute({visible: false});
    text2_3.setAttribute({visible: false});
    text2_4.setAttribute({visible: false});
    text2_5.setAttribute({visible: false});
    text2_6.setAttribute({visible: false});
    text2_7.setAttribute({visible: false});
    text2_8.setAttribute({visible: false});
    arrBor2_1.setAttribute({visible: false});
   // arrBor2_2.setAttribute({visible: false});
    arrBor2_3.setAttribute({visible: false});
    //arrBor2_4.setAttribute({visible: false});
    arrBor3_1.setAttribute({visible: false});
    //arrBor3_2.setAttribute({visible: false});
    arrBor3_3.setAttribute({visible: false});
    //arrBor3_4.setAttribute({visible: false});
}

redo.on('down',function(){
    i = i+1;
    if (i > maxSteps){
        i = maxSteps;
    }
	    eraseText();
    updateStepTexts();
});

undo.on('down',function(){
    i=i-1;
    if (i < 0){
        i = 0;
    }
    eraseText();
    updateStepTexts();
});

//Initial Step
var textInitial = brd4.create('text', [1.5, 2, '(a+b)^2'], {visible: true, fixed:true, fontSize:function(){return 48*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//FIRST STEP - VALUES IN LETTERS

//Values
var text1_1 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y()/2}, 'ab'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_2 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y()/2}, 'b^2'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_3 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, 'ab'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_4 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, 'a^2'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//Text arrows on border 2
var text1_5 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y()/2}, 'b'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_6 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, 'a'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//
var text0_1 = brd4.create('text', [total+1.5*offset, total/2, 'a+b'], {anchorX:'left',anchorY:'middle', highlight:false,visible: true, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text0_2 = brd4.create('text', [total/2, total+1.5*offset, 'a+b'], {anchorX:'middle', anchorY:'bottom', highlight:false,visible: true, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//Text arrows on border 3
var text1_7 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return total + 1.5*offset}, 'a'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text1_8 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return  total + 1.5*offset},'b'], {visible: false, fixed:true, fontSize:function(){return 24*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});


//SECOND STEP - VALUES IN NUMBERS

//Values
var text2_1 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y()/2}, function(){return (interiorPoint.X()*interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_2 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y()/2}, function(){return ((total-interiorPoint.X())*(interiorPoint.Y())).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_3 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, function(){return (interiorPoint.X()*interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_4 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, function(){return (interiorPoint.X()*interiorPoint.X()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
//Text arrows on border 2
var text2_5 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y()/2}, function(){return (interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_6 = brd4.create('text', [function(){return total + offset + 0.1}, function(){return interiorPoint.Y() + (total - interiorPoint.Y())/2}, function(){return (total - interiorPoint.Y()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

//Text arrows on border 3
var text2_7 = brd4.create('text', [function(){return interiorPoint.X()/2-0.1}, function(){return total + offset + 0.15}, function(){return (interiorPoint.X()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
var text2_8 = brd4.create('text', [function(){return interiorPoint.X()-0.1 + (total - interiorPoint.X())/2}, function(){return  total + offset + 0.15}, function(){return (total-interiorPoint.X()).toFixed(0)}], {visible: false, fixed:true, fontSize:function(){return 18*brd4.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
brd4.unsuspendUpdate();
},
box4a: function () {
var brd3 = JXG.JSXGraph.initBoard('jxgbox4a',{boundingbox: [-10, 30, 30,-10],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}});
brd3.suspendUpdate();
brd3.options.layer['image']=16;
brd3.options.layer['text']=17;
/*var resize = function () {
         brd3.resizeContainer(brd3.containerObj.clientWidth, brd3.containerObj.clientHeight, true);
         brd3.fullUpdate();
       };
window.onresize = resize;*/
makeResponsive(brd3);
brd3.create('text',[10, 28, '<b>For Loop</b> '],{highlight:false, cssStyle:'fontFamily:Oswald;', anchorX:'middle', anchorY: 'middle', fontSize:function(){return Math.round(32*brd3.canvasWidth/800.)}, fixed:true});
//congruent triangle//
placeLogo(brd3);
    var x =0.;
	  var y =0.;
	  var alpha =0.;
	  var imax =  brd3.create('input', [5, 24, 10, 'For&nbsp;[&nbsp;i&nbsp;=&nbsp;1;&nbsp;&nbsp;i++;&nbsp;&nbsp;i&nbsp;&lt; &nbsp;'], {cssStyle: 'fontFamily:Lato;width:5%;', fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)},fixed:true, maxlength:2});
	  //
	  var xpp = brd3.create('input', [5, 22, 1, '&nbsp;&nbsp;&nbsp;x&nbsp;=&nbsp;x&nbsp;+&nbsp;&nbsp;'], {cssStyle: 'fontFamily:Lato;width:5%;', fontSize:()=> Math.round(22*brd3.canvasWidth/800.),fixed:true, maxlength:1});
	  //
	  brd3.create('text', [11, 22, '&nbsp;&Delta;'], {cssStyle: 'fontFamily:Lato;', fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)},fixed:true});
	  //
	  var ypp = brd3.create('input', [5, 20, 1., '&nbsp;&nbsp;&nbsp;y&nbsp;=&nbsp;y&nbsp;+&nbsp;&nbsp;'], {cssStyle: 'fontFamily:Lato;width:5%;', border:{strokeWidth:2}, fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)},fixed:true, maxlength:1});
	 //
 brd3.create('text', [11, 20, '&nbsp;&Delta;'], {cssStyle: 'fontFamily:Lato;', fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)},fixed:true});
	 var pt2 = brd3.create('point', [0., 0.0],{withLabel:false, fixed:false, visible:false});
	 var pt3 = brd3.create('point', [0., 0.0],{withLabel:false, fixed:false, visible:false});
	 var pt33 = brd3.create('point', [5, 0.0],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false});
	 var pt40 = brd3.create('point', [function(){return xpp.Value()*5}, 0.0],{withLabel:false, fixed:false, visible:false});

	 var pt4 = brd3.create('point', [5, 0.0],{withLabel:false, fixed:false, visible:false});
	 var pt44 = brd3.create('point',[5, function(){return ypp.Value()*5/xpp.Value()}],{face:'o' , name:'pp', size:2, withLabel:false, fixed:false, visible:false});
//
	 var arr2 = brd3.create ('arrow', [pt2, pt3], {visible: false, name:'h', strokeColor:'#80879A', strokeWidth: 2});
	 var arr3 = brd3.create ('arrow', [pt33,pt4], {visible: false, name:'h', strokeColor:'#80879A', strokeWidth: 2});
	  //
	  brd3.create('image',['/assets/rectt.svg', [4.5, 9.25], [14, 24]], {fixed:true, highlight:false, fillOpacity:1, shadow:true});
      var t = brd3.create('turtle',[0, 0], {visible:false, strokeWidth:0});
	  var pts = brd3.create('point', [function(){return t.X()}, function(){return t.Y()}],{name:'', size:function(){return 2*brd3.canvasHeight/800},color:'black', trace:true});
	  brd3.create('image',['/assets/rabbit.svg', [function(){return t.X()-1.25}, function(){return t.Y()+0.25}], [2.5, 2.5]], {fixed:true});
      t.hideTurtle();
      //t.setPos(0,3);
      //t.setPenColor('purple');
      var i =1;
	  var runXcontrol= 0;
      //var beta =0;
      //Starting Point
      brd3.create('point', [0, 0],{name:'Start', size:function(){return 4*brd3.canvasHeight/800}, fillColor:'white', strokeColor:'black', fixed:true, label:{display:'internal', anchorX:'middle', anchorY:'top',fontSize:function(){return 18*brd3.canvasHeight/800}, offset:[0, -10], cssStyle:'fontFamily:Oswald;'}});
	  //Finish Point
	  brd3.create('point', [()=>x, ()=>y],{visible:()=>i==imax.Value(), name:'Finish', size:function(){return 4*brd3.canvasHeight/800}, fillColor:'white', strokeColor:'black', fixed:true, label:{display:'internal', anchorX:'middle',  anchorY:'top', fontSize:function(){return 18*brd3.canvasHeight/800}, offset:[0, -10], cssStyle:'fontFamily:Oswald;'}});
	  //
      //var tf = brd3.create('transform', [function(){return a.Y()-3+1}, function(){return a.Y()-3+1}], {type: 'scale'});
      var runX = function() {
	  if(runXcontrol!=1){return;}
		if (i <imax.Value()) {// stop after two rounds
          i += 1;
		  x = x +1*xpp.Value();
		  y = y +1*ypp.Value();
		  //t.forward(1);
		  t.moveTo([x, y]);
          //t.setPenColor('red');
        }
		else{return;}
		//if(i==10){
		//t.right(90);
		//alpha=-90;
		//t.setPenColor('blue');
		//}
        //if (i>=10 &&i<= 20) {// stop after two rounds
        //  i += 1;
		//  t.forward(1);
        //  t.setPenColor('blue');
		if(i<=imax.Value() & runXcontrol==1){
        setTimeout(runX, 600);
		}
       }
      //var hat =brd3.create('point', [-6.75, 4.], {visible:false});
      var sailor = brd3.create('image',['/assets/play-button.svg', [-6, 18.], [6, 6]], {fixed:true});
	   brd3.create('text', [-5, 24.5, "Tap to Start!"], {visible: true, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	   brd3.create('text', [10, 16, "Orange fields are modifiable."], {anchorX:'middle', anchorY:'middle', visible: true, fixed: true, fontSize:function(){return 18*brd3.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});
	  var nmax =brd3.create('image',['/assets/yellowLight.svg', [25, 22], [2, 2]], {fixed:true, highlight:false, fillOpacity:function(){if(i==1){return 1} else{return 0.1}}});
      var nx =brd3.create('image',['/assets/redLight.svg', [25, 20], [2, 2]], {fixed:true, highlight:false, fillOpacity:function(){if(i>1 && i<imax.Value()){return 1} else{return 0.1}}});
	  var ny = brd3.create('image',['/assets/greenLight.svg', [25, 18], [2, 2]], {fixed:true, highlight:false, fillOpacity:function(){if(i==imax.Value()){return 1} else{return 0.1}}});
	  //var spe = brd3.create('image',['/assets/speaker.svg', [-6.8, 1.7], [0.75, 0.75]], {fixed:true, visible:function(){if(alpha > 0 && alpha < 17 || alpha > 20 && alpha < 40 || alpha >=45 && alpha < 60 || alpha >=64 && alpha <= 80 || alpha >80 && alpha<90 || alpha >92){return true } else {return false}}});
      //var rope = brd3.create('image',['assets/fiber.svg', [-4.25, 3.], [0.5, 0.5]], {attractors:[hat],attractorDistance:1,  snatchDistance:2.});
      //var sail = function(){if(rope.X()-hat.X()<=0.0001 && rope.Y()-hat.Y()<=0.0001){return run();}};
	  var res = function(){
		  pts.moveTo([0, 0]);
		  alpha=0;
		  t.cs();
		  i=1;
		  runXcontrol=0;
		  x=0;
		  y=0;
		  t.moveTo([0,0]);
		  pts.clearTrace();
		  t.left(0);
		  pt3.moveTo([0,0]);
		  pt4.moveTo([5,0]);
		  slopeX.setAttribute({visible: false});
		  slopeY.setAttribute({visible: false});
	  };
	  sailor.on('down', function(){res(); runXcontrol=1; runX(); slope1(100); slope2(500);});
	  //brd3.create('image',['/assets/start.svg', [-5, 14.], [4, 4]], {fixed:true, visible:()=>i==1});
	  //brd3.create('image',['/assets/finish.svg', [-5, 14.], [4, 4]], {fixed:true, visible:()=>i==imax.Value()});
      brd3.create('text', [23, 23, function(){return 'i = ' + i;}],{visible:()=> i==1, display:'internal', color:'blue', anchorX:'middle', anchorY: 'middle', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)}, parse:true,fixed:true});
	  //
	  brd3.create('text', [23, 21, function(){return 'i = ' + i;}],{visible:()=> i>1&&i<imax.Value(),display:'internal', color:'blue', anchorX:'middle', anchorY: 'middle', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)}, parse:true,fixed:true});
	  //
	  brd3.create('text', [23, 19, function(){return 'i = ' + i;}],{visible:()=> i==imax.Value(), display:'internal', color:'blue', anchorX:'middle', anchorY: 'middle', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(22*brd3.canvasWidth/800.)}, parse:true,fixed:true});

	  brd3.create('text', [5, 18, "End"], {visible: true, fixed: true, fontSize:function(){return 22*brd3.canvasHeight/800}, cssStyle:'fontFamily:Lato;'});
	   brd3.create('text', [16.7, 24, "]"], {visible: true, fixed: true, fontSize:function(){return 22*brd3.canvasHeight/800}, cssStyle:'fontFamily:Lato;'});
//
	  var slopeX = brd3.create('text',[function(){return pt33.X()/2}, function(){return pt2.Y()-1}, function(){return xpp.Value() +'&Delta;'}],{visible: false, cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd3.canvasWidth/500.)},fixed:true});
//
      var slopeY = brd3.create('text',[function(){return pt33.X() + 0.2}, function(){return pt4.Y()/2 }, function(){return ypp.Value()+'&Delta;'}],{visible: false, cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(12*brd3.canvasWidth/500.)},fixed:true});
function movePoint3(){
            pt3.moveTo([pt33.X(), pt33.Y()], 500);
            arr2.setAttribute({visible: true});
            slopeX.setAttribute({visible: true});
            }
//
		function slope1(t){
		setTimeout(function(){ movePoint3(); }, t);
		}
//-------------------
	   function movePoint4(){
			pt4.moveTo([pt33.X(), pt33.Y()+5*ypp.Value()/xpp.Value()], 500);
            arr3.setAttribute({visible: true});
            slopeY.setAttribute({visible: true});}
//
   	function slope2(t){
    setTimeout(function(){movePoint4(); }, t);
	}
//
 brd3.unsuspendUpdate();
 let magicGraph = document.querySelector("#jxgbox4a");
 let inputFields = magicGraph.querySelectorAll("input");
 var onEachPress = function(event) { res(); }
 inputFields.forEach(function(field)
 {
   field.addEventListener('input', onEachPress);
 }
 );
},
box6a:function () {
  JXG.Options.board.minimizeReflow = 'none';
  var brd6 = JXG.JSXGraph.initBoard('jxgbox6a',{boundingbox: [-2, 22, 24, -4],keepaspectratio: true, axis:false, ticks:false, grid:true, pan:{enabled:false}, zoom:{enabled:false}, showCopyright:false, showNavigation:false});
  brd6.options.image.highlight=false;
  brd6.options.image.opacity=1;
  brd6.options.text.highlight=false;
  makeResponsive(brd6);
  placeLogo(brd6);
  //brd6.create('text', [11, 20, function(){return 'Finding Probability'}],{display:'internal', highlight:false, anchorX:'middle',fontSize:function(){return 32*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});
  placeTitle(brd6, 'Probability in Game of Dice');
  brd6.create('text', [0, 18, function(){return 'Enter the Desired Sum Total Below (between 2 & 12)'}],{display:'internal', highlight:false, fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
  var sum =brd6.create('input', [0, 16.8, ' ' , ''], {cssStyle:'width:5%',
  fontSize:function(){return 20*brd6.canvasHeight/800}, fixed:true});//
  //brd2.create('input', [0, 6.8, 2, ''], {cssStyle:'width:5%;fontFamily:Oswald;background-color:white;border:0px solid black;border-radius: 3.5px;',fontSize:function(){return 20*brd2.canvasHeight/800}, fixed:true});//
  sum.setLabel('Step # 1: Enter the sum total here')
  sum.label.setAttribute({visible:false, offset:[45, 0], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd6.canvasHeight/800}});
  sum.on('over', function () {this.label.setAttribute({visible:true});});
  sum.on('out', function () {this.label.setAttribute({visible:false});});
  //
  brd6.create('text', [0, 15.5, function(){return 'Tap at the Hand to Roll the Dice!'}],{fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
  var player = brd6.create('image', ['/assets/dices.svg', [2, 10],[4.0,4.0]],{fixed:true, shadow:false, rotate:20});
  player.setLabel('Step # 2: Tap to Roll the Dice!')
  player.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:function(){return 20*brd6.canvasHeight/800}});
  player.on('over', function () {this.label.setAttribute({visible:true});});
  player.on('out', function () {this.label.setAttribute({visible:false});});
  var i=0;
  var j=0;
  var cnt = 0;
  var tup = [];
  var k =1;
  var m =1;
  var km=1;
  var vis=0;
  var ind = brd6.create('text', [17, 18, function(){return 'Outcomes with Sum Total of ' + sum.Value()}],{visible:()=>vis==1, display:'internal', anchorX:'middle', highlight:false,
  fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});

  /*var pop = function(){cnt=0;
  for(i=1; i<=6; i++){
  for(j=1; j<=6; j++){
  cnt+=1;
  tup[cnt-1] = [1, j]}
  }};*/
  var run = function(n){cnt=0;
  for(i=1; i<=6; i++){
  for(j=1; j<=6; j++){
  if(i+j ==n){
  cnt+=1;
  tup[cnt-1] = [i, j]}
  }}};
  var pt =function(){
  for(km=1; km<=36; km++){
  brd6.create('text', [19, 15.5-3*(km-1), function(){return '( '+tup[km-1][0]+' + '+tup[km-1][1]+' = '+(tup[km-1][0]+tup[km-1][1]).toFixed(0)+' )'}],
  {display:'internal', fontSize:function(){return 28*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
  }
  }
  var img=['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n','o', 'p', 'q', 'r', 's', 't'];
  var nme=[];
  var ini = function(){
  brd6.suspendUpdate();
  ind.setAttribute({visible:false});
  txt1.setAttribute({visible:false});
  txt2.setAttribute({visible:false});
  txt3.setAttribute({visible:false});
  txt4.setAttribute({visible:false});
  txt5.setAttribute({visible:false});
  txt6.setAttribute({visible:false});
  txt7.setAttribute({visible:false});
  txt8.setAttribute({visible:false});
  for (m=cnt; m>=1; m--){
  brd6.removeObject(img[2*(m-1)+1],true);
  brd6.removeObject(img[2*(m-1)+2], true);
  brd6.removeObject(nme[m]);
  }
  brd6.unsuspendUpdate();
  }
  var dice = function(){
  ind.setAttribute({visible:true})
  txt1.setAttribute({visible:true});
  txt2.setAttribute({visible:true});
  txt3.setAttribute({visible:true});
  txt4.setAttribute({visible:true});
  txt5.setAttribute({visible:true});
  txt6.setAttribute({visible:true});
  txt7.setAttribute({visible:true});
  txt8.setAttribute({visible:true});
  for (k=1; k<=cnt; k++){
  brd6.create('image', ['/assets/dice-'+tup[k-1][0]+'-red.svg', [13, 17.5-3*k],[2, 2]], {name:img[2*(k-1)+1], fixed:true, highlight:false});
  brd6.create('image', ['/assets/dice-'+tup[k-1][1]+'-green.svg', [17.5,17.5-3*k],[2, 2]],{name:img[2*(k-1)+2], rotate:70, fixed:true, highlight:false});
  nme[k]=brd6.create('text', [19, 15.5-3*(k-1),'( '+tup[k-1][0]+' + '+tup[k-1][1]+' = '+(tup[k-1][0]+tup[k-1][1]).toFixed(0)+' )'],
  {display:'internal', fontSize:function(){return 28*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});

  };
  };
  player.on('down', function(){i=0; j=0; k=1; m=1; tup=[];ini();run(sum.Value()); dice(); vis=1; brd6.update();});
  var txt1 =brd6.create('text', [0, 8, function(){return 'Number of Outcomes with Sum Total of '+sum.Value()}],{highlight:false, visible:false, display:'internal',fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
  var txt2 =brd6.create('text', [5, 7, function(){return  ''+ cnt }],{highlight:false ,anchorX:'middle', display:'internal', color:'blue',visible:false, fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

  var txt3 =brd6.create('text', [0, 5.5, function(){return 'Probability of Getting a Sum Total of '+sum.Value()}],{highlight:false ,visible:false, display:'internal',fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
  var txt4 =brd6.create('text', [5, 4.5, function(){return  ''+ cnt + ' in 36'}],{highlight:false , anchorX:'middle',color:'blue',visible:false, display:'internal', fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

  var txt5 =brd6.create('text', [0, 3., 'Probability Expressed as a Fraction'],{highlight:false ,display:'internal', visible:false,fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
  var txt6 =brd6.create('text', [5, 2., function(){return  (cnt/36).toFixed(3)}],{highlight:false ,anchorX:'middle',color:'blue',visible:false, display:'internal', fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

  var txt7 =brd6.create('text', [0, 0.5, 'Probability Expressed as a Percentage'],{highlight:false ,display:'internal',visible:false, fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
  var txt8 =brd6.create('text', [5, -0.5, function(){return  (100*cnt/36).toFixed(3) + '%'}],{highlight:false,visible:false,anchorX:'middle',color:'blue', display:'internal', fontSize:function(){return 20*brd6.canvasHeight/800}, cssStyle:'fontFamily:Oswald;fontWeight:bold', fixed:true});

  brd6.update();
  /////////////////////////////////////////////////////
  let magicGraph = document.querySelector("#jxgbox6a");
  let inputFields = magicGraph.querySelectorAll("input");
  var onEachPress = function(event) { ini(); vis=0 }
     inputFields.forEach(function(field) {
     field.addEventListener('input', onEachPress);});
  },
box5a: function () {
  JXG.Options.layer['polygon'] =1;
  JXG.Options.layer['line'] =4;
  JXG.Options.layer['point'] =4;
  JXG.Options.layer['circle'] =2;
  var brd5 = JXG.JSXGraph.initBoard('jxgbox5a',{boundingbox: [-2, 5, 6,-3],keepaspectratio:true, axis:false, ticks:false, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  brd5.suspendUpdate();
  makeResponsive(brd5);
  placeLogo(brd5);
  //brd5.create('text', [2, 4.5, '<b>Crank-Shaft Mechanism</b>'],  {anchorX:'middle',fontSize:function(){return Math.round(32*brd5.canvasWidth/800.)}});
  placeTitle(brd5, 'Crank-Shaft Mechanism');
  brd5.create('text', [2, 3.5, '1. Select disc size. 2. Select shaft length. 3. Tap on play button to start.'],
  {display:'internal',anchorX:'middle', anchorY:'middle', highlight:false, fixed:true, fontSize:function(){return 24*brd5.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'});

  /*var r = brd5.create('slider',[[1, 2.75],[4,2.75],[0.5, 0.75, 1.]],{unitLabel:'m',highlight:false,
    baseline:{strokeWidth:function(){return 7*brd5.canvasHeight/800}, strokeColor:'grey'},
    highline:{strokeWidth:function(){return 7*brd5.canvasHeight/800}, strokeColor:'black'},
    name:' Disc',size:function(){return 10*brd5.canvasHeight/800},face:'square', fillColor:'#008CBA',strokeColor:'black', withTicks:false,
    label:{fontSize:function(){return 24*brd5.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}}); */

  var r = placeSlider(brd5, 1, 2.75, 0.5, 0.75, 1, 3, 'Disc');
  r.setAttribute({fillColor:'#008CBA', unitLabel:'m'});
  //Rota Path
/*  var l = brd5.create('slider',[[1, 2],[4.,2],[3.0, 3.0, 4.0]],
  {unitLabel:'m',highlight:false, baseline:{strokeWidth:function(){return 7*brd5.canvasHeight/800}, strokeColor:'grey'},
  highline:{strokeWidth:function(){return 7*brd5.canvasHeight/800}, strokeColor:'black'}, name:' Shaft',
  size:function(){return 10*brd5.canvasHeight/800},face:'square', fillColor:'#f44336',strokeColor:'black',
  withTicks:false,label:{fontSize:function(){return 24*brd5.canvasHeight/800},cssStyle:'fontFamily:Oswald;'}});*/

  var l = placeSlider(brd5, 1, 2, 3, 3, 4, 3, 'Shaft');
  l.setAttribute({fillColor:'#f44336', unitLabel:'m'});

  //
  var stator=brd5.create('circle',[[0, 0],[1.25, 0.0]],
  {highlight:false, strokeColor:'grey', fillColor:'grey',fixed:true, shadow:false, highlight:false});
  //
  var rota=brd5.create('circle',[[0, 0],[function(){return r.Value()}, 0.0]],
  {highlight:false,strokeWidth:1, strokeColor:'black', fillcolor:'white',fixed:true, name:'', withLabel:true, shadow:false});
  //rota.label.setAttribute({visible:false});
  //rota.on('over', function () {this.label.setAttribute({visible:true});});
  //rota.on('out', function () {this.label.setAttribute({visible:false})});
  //Glider
  var rxa=brd5.create('glider',[1.00, 0.0, rota],
  {name:' ',size:()=>3*brd5.canvasHeight/800, face:'circle',fillColor:'#008CBA',strokeColor:'#f44336', strokeWidth:()=>3*brd5.canvasHeight/800, shadow:false});
  //segment
  //var point1 = brd5.create('point', [function(){return rxa.X()+Math.sqrt(l.Value()*l.Value() - rxa.Y()*rxa.Y())+0.175;},0.0], {name: '', size:0 });
  var point2 = brd5.create('point', [function(){return rxa.X()+Math.sqrt(l.Value()*l.Value() - rxa.Y()*rxa.Y());},0.0],
  {name: '', size:function(){return Math.round(24*brd5.canvasWidth/800.)}, face:'square', fillColor:'black', strokeColor:'black'});
  //
  //Path
  var axiis=brd5.create('line',[[0.0, 0.0],[1.0, 0.0]],{fixed:true, visible:false});
  //Piston
  var piston=brd5.create('glider',[function(){return rxa.X()+Math.sqrt(l.Value()*l.Value() - rxa.Y()*rxa.Y());},0.0, axiis],
  {highlight:false, name:'',size:()=>3*brd5.canvasHeight/800,face:'circle', strokeWidth:()=>3*brd5.canvasHeight/800, fillColor:'black',
  strokeColor:'#f44336',shadow:false, name:'Piston', withLabel:true,label:{offset:[24, 0], fontSize:()=>24*brd5.canvasHeight/800},});

  //piston.on('over', function () {this.label.setAttribute({visible:true});});
  //piston.on('out', function () {this.label.setAttribute({visible:false})});
  //
  //limit
  brd5.create('segment', [[function(){return Math.abs(r.Value()+l.Value());},1], [function(){return Math.abs(r.Value()+l.Value());}, -1.0]], {dash:1, strokeWidth:()=>2*brd5.canvasHeight/800});
  brd5.create('segment', [[function(){return Math.abs(r.Value()-l.Value());},1.0], [function(){return Math.abs(r.Value()-l.Value());}, -1.0]], {dash:1,strokeWidth:()=>2*brd5.canvasHeight/800 });
  var tape= brd5.create('tapemeasure', [[function(){return Math.abs(r.Value()-l.Value());},-1.0], [function(){return Math.abs(r.Value()+l.Value());}, -1.0]],
  {unitLabel:'m',label:{anchorX:'right', anchorY:'bottom', fontSize:()=>22*brd5.canvasHeight/800},withTicks:false, point1:{face:'<', size:()=>4*brd5.canvasHeight/800, fillColor:'black'}, point2:{face:'>', size:4*brd5.canvasHeight/800, fillColor:'black'}});
  //tape.label.setAttribute({anchorX:'middle', fontSize:()=>24*brd5.canvasHeight/800});
  //Path
  var rail1=brd5.create('segment',[[1.5, 0.3],[5.5, 0.3]],{strokeColor:'grey', strokeWidth:()=>12*brd5.canvasHeight/800, fixed:true, shadow:false});
  var rail2=brd5.create('segment',[[1.5, -0.3],[5.5, -0.3]],{strokeColor:'grey', strokeWidth:()=>12*brd5.canvasHeight/800, fixed:true, shadow:false});
  //var arcc =brd5.create('arc', [[0,0]])
  //
var connection=brd5.create('segment', [rxa, piston], {strokeColor:'#f44336', strokeWidth:()=>8*brd5.canvasHeight/800, name:'Shaft',
withLabel:true,label:{anchorX:'right', anchorY:'bottom', fontSize:()=>24*brd5.canvasHeight/800}});
//connection.on('over', function () {this.label.setAttribute({visible:true});});
//connection.on('out', function () {this.label.setAttribute({visible:false})});
//rad
var rad=brd5.create('segment', [rxa, [0, 0.0]], {strokeColor:'#008CBA', strokeWidth:()=>8*brd5.canvasHeight/800, name:'Disc',
withLabel:true,label:{anchorX:'right', anchorY:'bottom', fontSize:()=>24*brd5.canvasHeight/800}});
//rad.on('over', function () {this.label.setAttribute({visible:true});});
//rad.on('out', function () {this.label.setAttribute({visible:false})});
//var graph = brd5.create('functiongraph', [function(x){return a.Value()*Math.sin(w.Value()*x + c.Value());}, 0.0, 10*Math.PI], {strokeColor:'#aa2233',strokeWidth:3});
//var title= brd5.create('text',[0.,2.75, 'Mechanics | Crank-Shaft Mechanism'],{fontColor:'black',fontSize:20, useMathJax:true,parse:false,fixed:true});
//
var cent= brd5.create('point',[0.0,0.0],{face:'circle', strokeWidth:()=>3*brd5.canvasHeight/800, strokeColor:'#008CBA', fillColor:'white', fixed:true, name:''});
//setInterval(function(){rxa.moveTo([r.Value()*Math.cos(il*Math.PI/12),r.Value()*Math.sin(il*Math.PI/12)],30); il++;},200);
var play = placePlay(brd5, 'left');
var pause= placePause(brd5, 'right');
// brd5.create('image', ['/assets/turn-off.svg', [-1, 3],[1,1]],{highlight:false, fixed:true, shadow:false});
var x =0; var y=0; var tt=0; var i=0;
/*var run =function(){
if(tt<=800 && i>0){
tt +=1;
x = r.Value()*Math.cos(tt*Math.PI/80);
y = r.Value()*Math.sin(tt*Math.PI/80);
}
rxa.moveTo([x,y]);
if(tt<=800 && i>0){
setTimeout(run, 0);}
else{return;}
}*/
play.on('down', function(){rxa.startAnimation(-1, 200, 5)});
pause.on('down', function(){rxa.stopAnimation()});

r.on('down', function(){x=0; y=0; i=0});
l.on('down', function(){x=0; y=0; i=0});
brd5.unsuspendUpdate();
},
box7a: function () {
  JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  //////////////////////////////////LOCAL SETTINGS//////////////////////////////////
  var graph =createSpace(-10,10,-10,10);
  makeResponsive(graph);
  placeLogo(graph);
  graph.suspendUpdate();
  graph.options.layer['image']=26;
  graph.options.layer['text']=5;
  graph.options.layer['html']=2;
  graph.options.layer['line']=2;
  graph.options.layer['point']=10;
  graph.options.layer['circle']=1;
  graph.options.image.highlight=false;
  /*********************GRAPH DIMENSIONS*******************/
  const boundingBox = graph.attr.boundingbox;
  const positionX = (boundingBox[2]+boundingBox[0])/2;
  const positionY = (boundingBox[1]+boundingBox[3])/2;
  const height = (boundingBox[1]-boundingBox[3])/2;
  //////////////////////////////////////////////////////////////////////////////
//Place Title
  placeTitle(graph, 'Venn Diagram', '(Sets, their Union & Intersection)');
//
  placeBoldText(graph, 0, 6.5, 'Select regions that belong to:')
/////////////////////////////////////////////////////////////////

/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'right');
hoverMe(graph, test, 'Check Your Answer', -10, -10);
/**/
var shuffle = placeShuffle(graph);
hoverMe(graph, shuffle, 'Next Question', 0, -10);
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, -10);
/***************************************/
var txt1 = graph.create('text', [4.1, 6.5, ' A ⋃ B'],{display:'internal',visible: true, fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald;face:bold',fontSize:function(){return Math.round(22*graph.canvasWidth/800.)}},);
//Correct or erase function whiteEverithing ...     !!!!
//Variables
var tx = -1;
var ty = -10;
var posx_ed1 = -10;
var posy_ed1 = 9;
var sizeOk = 1.0;
var size_ed = 0.8;
var size_tap = 1;
var max_pages = 5;
var current_page = 0;
var pages = [];

var right_answer = 0;

let i=0;
var maxSteps = 5;

var radius = 2.5;
var ax = -2;
var ay = -1.5;
var bx = 2;
var by = -1.5;
var cx = 0;
var cy = 1.5;

var wpol = 5;
var hpol = 5;

var color1 = '#ffffff';
var color2 = '#FF3B3B ';
var color4 = '#5B43FF ';

var current_colorA = color1;
var current_colorB = color1;
var current_colorC = color1;

var current_colorAB = color1;
var current_colorAC = color1;
var current_colorBC = color1;

var current_colorABC = color1;

var current_colorUniverse = color1;


JXG.joinCurves = function(board, parents, attributes) {
    var curves = parents,
        attr = JXG.copyAttributes(attributes, board.options, 'curve'),
        c = board.create('curve', [[0], [0]], attr);

    c.updateDataArray = function() {
        var i = curves.length;

        // The paths have to be connected
        this.dataX = [];
        this.dataY = [];
        for (i = 0; i < curves.length; i++) {
            if (i < curves.length - 1) {
                this.dataX = this.dataX.concat(curves[i].dataX.slice(0,-1));
                this.dataY = this.dataY.concat(curves[i].dataY.slice(0,-1));
            } else {
                this.dataX = this.dataX.concat(curves[i].dataX);
                this.dataY = this.dataY.concat(curves[i].dataY);
            }
        }

        if (this.dataX.length<4) {
            this.bezierDegree = 1;
        } else {
            this.bezierDegree = curves[0].bezierDegree;
        }
    };
    c.prepareUpdate().update().updateVisibility().updateRenderer();
    return c;
};

//Tetxs
var txt_A = graph.create('text', [ax, ay, 'A'],{display:'internal',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);
var txt_B = graph.create('text', [bx, by, 'B'],{display:'internal',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);
var txt_C = graph.create('text', [cx, cy, 'C'],{display:'internal',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);
var txt_U = graph.create('text', [-4, 4, 'U'],{display:'internal', visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);


//Polygon
//var universe = graph.create('polygon', [[-wpol, -hpol], [wpol, -hpol], [wpol, hpol], [-wpol, hpol]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 6, highlightStrokeColor: 'black'}, fillColor: color1, highlightfillColor: color1, highlightfillOpacity: 1.0, fillOpacity:1.0 , layer: 2});
//var universe = placeCircle(graph, [0,0], [5, 0], 'black');
var universe =graph.create('circle', [[0,0], [7, 0]], {fixed:true, fillOpacity:0.5, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor:color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2})
// Create two arcs (or circles)
var p1 = graph.create('point', [ax, ay], {face:'o' , name:'p1', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p2 = graph.create('point', [ax + radius, ay], {face:'o' , name:'p2', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var a1 = graph.create('circle', [p1,p2], {fillOpacity:0.75, strokeColor: 'black', dash:1, strokeWidth: 1, fillColor: color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color1});

var p3 = graph.create('point', [bx, by], {face:'o' , name:'p3', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p4 = graph.create('point', [bx + radius, by], {face:'o' , name:'p4', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var a2 = graph.create('circle', [p3,p4], {fillOpacity:0.75, strokeColor: 'black', dash:1, strokeWidth: 1, fillColor: color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color1});

var p5 = graph.create('point', [cx, cy], {face:'o' , name:'p5', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var p6 = graph.create('point', [cx + radius, cy], {face:'o' , name:'p6', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var a3 = graph.create('circle', [p5,p6], {fillOpacity:0.75, strokeColor: 'black', dash:1, strokeWidth:1, fillColor: color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color1});


// Create two intersection points
var i1 = graph.create('intersection', [a1,a2,1], {visible:false, name:'i1', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var i2 = graph.create('intersection', [a1,a3,0], {visible:false, name:'i2', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var i3 = graph.create('intersection', [a2,a3,1], {visible:false, name:'i3', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var i4 = graph.create('intersection', [a1,a2,0], {visible:false, name:'i4', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var i5 = graph.create('intersection', [a1,a3,1], {visible:false, name:'i5', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
var i6 = graph.create('intersection', [a2,a3,0], {visible:false, name:'i6', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});


// Create three arcs surrounding the intersection area
var c1 = graph.create('arc', [p1, i2, i1], {visible:false, strokeColor: 'black', strokeWidth: 0});
var c2 = graph.create('arc', [p3, i1, i3], {visible:false, strokeColor: 'black', strokeWidth: 0});
var c3 = graph.create('arc', [p5, i3, i2], {visible:false, strokeColor: 'black', strokeWidth: 0});

// Create three arcs surrounding the intersection area AC
var c4 = graph.create('arc', [p1, i2, i5], {visible:false, strokeColor: 'black', strokeWidth: 0});
var c5 = graph.create('arc', [p5, i5, i2], {visible:false, strokeColor: 'black', strokeWidth: 0})

// Create three arcs surrounding the intersection area BC
var c6 = graph.create('arc', [p3, i6, i3], {visible:false, strokeColor: 'black', strokeWidth: 0});
var c7 = graph.create('arc', [p5, i3, i6], {visible:false, strokeColor: 'black', strokeWidth: 0})

// Create three arcs surrounding the intersection area BC
var c8 = graph.create('arc', [p1, i4, i1], {visible:false, strokeColor: 'black', strokeWidth: 0});
var c9 = graph.create('arc', [p3, i1, i4], {visible:false, strokeColor: 'black', strokeWidth: 0})


// Join the three arcs and fill the area.

var AC = JXG.joinCurves(graph, [c4, c5],
    {fillColor:color1, fillOpacity:0.5, strokeColor: 'black', strokeWidth:1, dash:1
});

var BC = JXG.joinCurves(graph, [c7, c6],
    {fillColor:color1, fillOpacity:0.75, strokeColor: 'black', strokeWidth:1, dash:1
});


var AB = JXG.joinCurves(graph, [c9, c8],
    {fillColor:color1, fillOpacity:0.75, strokeColor: 'black', strokeWidth:1, dash:1
});

var ABC = JXG.joinCurves(graph, [c1, c2, c3],
    {fillColor:color1, fillOpacity:0.75, strokeColor: 'black', strokeWidth:1, dash:1
});


//Functions
var getMouseCoords = function(e, i) {
    var cPos = graph.getCoordsTopLeftCorner(e, i),
        absPos = JXG.getPosition(e, i),
        dx = absPos[0]-cPos[0],
        dy = absPos[1]-cPos[1];

    return new JXG.Coords(JXG.COORDS_BY_SCREEN, [dx, dy], graph);
}

var colorThings = function(e) {
    var canCreate = true, i, coords, el;

    if (e[JXG.touchProperty]) {
        // index of the finger that is used to extract the coordinates
        i = 0;
    }
    coords = getMouseCoords(e, i);

    for (el in graph.objects) {
        if(JXG.isPoint(graph.objects[el]) && graph.objects[el].hasPoint(coords.scrCoords[1], coords.scrCoords[2])) {
            canCreate = false;
            break;
        }
    }

    if (canCreate) {

        var posx = coords.usrCoords[1];
        var posy = coords.usrCoords[2];

        insideA(posx, posy);
        insideB(posx, posy);
        insideC(posx, posy);

        colorA(posx, posy);
        colorB(posx, posy);
        colorC(posx, posy);

        colorAB(posx, posy);
        colorAC(posx, posy);
        colorBC(posx, posy);
        colorABC(posx, posy);

        colorUniverse(posx, posy)

    }
}


function insideA(posx, posy){
    if( Math.pow( (posx - ax) ,2) + Math.pow( (posy - ay) ,2) <= radius*radius ){
        return true;
    }
    else{
        return false;
    }
}

function insideB(posx, posy){
    if( Math.pow( (posx - bx) ,2) + Math.pow( (posy - by) ,2) <= radius*radius ){
        return true;
    }
    else{
        return false;
    }
}

function insideC(posx, posy){
    if( Math.pow( (posx - cx) ,2) + Math.pow( (posy - cy) ,2) <= radius*radius ){
        return true;
    }
    else{
        return false;
    }
}

function colorA(posx, posy){
    if(insideA(posx, posy) == true && insideB(posx, posy) == false && insideC(posx, posy) == false){
        if(current_colorA == color2){
            a1.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorA = color1;
        }
        else{
            a1.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorA = color2;
        }
    }

}

function colorB(posx, posy){
    if(insideB(posx, posy) == true && insideA(posx, posy) == false && insideC(posx, posy) == false){
        if(current_colorB == color2){
            a2.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorB = color1;
        }
        else{
            a2.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorB = color2;
        }
    }
}

function colorC(posx, posy){
    if(insideC(posx, posy) == true && insideA(posx, posy) == false && insideB(posx, posy) == false){
        if(current_colorC == color2){
            a3.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorC = color1;
        }
        else{
            a3.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorC = color2;
        }
    }
}

function colorAB(posx, posy){
    if(insideA(posx, posy) == true && insideB(posx, posy) == true && insideC(posx, posy) == false){
        if(current_colorAB == color2){
            AB.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorAB = color1;
        }
        else{
            AB.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorAB = color2;
        }
    }
}

function colorAC(posx, posy){
    if(insideA(posx, posy) == true && insideB(posx, posy) == false && insideC(posx, posy) == true){
        if(current_colorAC == color2){
            AC.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorAC = color1;
        }
        else{
            AC.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorAC = color2;
        }
    }
}

function colorBC(posx, posy){
    if(insideA(posx, posy) == false && insideB(posx, posy) == true && insideC(posx, posy) == true){
        if(current_colorBC == color2){
            BC.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorBC = color1;
        }
        else{
            BC.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorBC = color2;
        }
    }
}

function colorABC(posx, posy){
    if(insideA(posx, posy) == true && insideB(posx, posy) == true && insideC(posx, posy) == true){
        if(current_colorABC == color2){
            ABC.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorABC = color1;
        }
        else{
            ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorABC = color2;
        }
    }
}

function colorUniverse(posx, posy){
    if(insideA(posx, posy) == false && insideB(posx, posy) == false && insideC(posx, posy) == false && posx <= wpol && posx >= -wpol && posy <= hpol && posy >= -hpol){
        if(current_colorUniverse == color2){
            universe.setAttribute({fillColor:color1, highlightfillColor: color1});
            current_colorUniverse = color1;
        }
        else{
            universe.setAttribute({fillColor:color2, highlightfillColor: color2});
            current_colorUniverse = color2;
        }
    }
}


graph.on('down', colorThings);

//Images
//var tap = graph.create('image', ['/assets/test.svg', [5,7.5], [size_tap, size_tap]], {visible: true, fixed: true});
//Functions
function whiteEverything(){
    if(current_colorA == color2){
        a1.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorA = color1;
    }
    if(current_colorB == color2){
        a2.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorB = color1;
    }
    if(current_colorC == color2){
        a3.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorC = color1;
    }
    if(current_colorAB == color2){
        AB.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorAB = color1;
    }
    if(current_colorAC == color2){
        AC.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorAC = color1;
    }
    if(current_colorBC == color2){
        BC.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorBC = color1;
    }
    if(current_colorABC == color2){
        ABC.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorABC = color1;
    }
    if(current_colorUniverse == color2){
        universe.setAttribute({fillColor:color1, highlightfillColor: color1});
        current_colorUniverse = color1;
    }
}

function solution1(){
    if(
        current_colorA == color2 &&
        current_colorB == color2 &&
        current_colorC == color1 &&
        current_colorAB == color2 &&
        current_colorAC == color2 &&
        current_colorBC == color2 &&
        current_colorABC == color2 &&
        current_colorUniverse == color1
    ){
        return true;
    }
    else{
        return false;
    }
}

function solution2(){
    if(
        current_colorA == color1 &&
        current_colorB == color1 &&
        current_colorC == color1 &&
        current_colorAB == color1 &&
        current_colorAC == color1 &&
        current_colorBC == color1 &&
        current_colorABC == color2 &&
        current_colorUniverse == color1
    ){
        return true;
    }
    else{
        return false;
    }
}

function solution3(){
    if(
        current_colorA == color2 &&
        current_colorB == color2 &&
        current_colorC == color2 &&
        current_colorAB == color2 &&
        current_colorAC == color2 &&
        current_colorBC == color1 &&
        current_colorABC == color1 &&
        current_colorUniverse == color2
    ){
        return true;
    }
    else{
        return false;
    }
}

function solution4(){
    if(
        current_colorA == color2 &&
        current_colorB == color2 &&
        current_colorC == color1 &&
        current_colorAB == color2 &&
        current_colorAC == color1 &&
        current_colorBC == color2 &&
        current_colorABC == color1 &&
        current_colorUniverse == color1
    ){
        return true;
    }
    else{
        return false;
    }
}

function solution5(){
    if(
        current_colorA == color1 &&
        current_colorB == color1 &&
        current_colorC == color1 &&
        current_colorAB == color1 &&
        current_colorAC == color2 &&
        current_colorBC == color2 &&
        current_colorABC == color2 &&
        current_colorUniverse == color1
    ){
        return true;
    }
    else{
        return false;
    }
}

function shuffleit()
{
 if(i == 0)
 {
     txt1.setText('A ⋂ B ⋂ C');
 }
 else if( i == 1)
 {
     txt1.setText('U - B ⋂ C')
 }
 else if( i == 2)
 {
     txt1.setText('A ⋃ B - C ⋂ A')
 }
 else if( i == 3)
 {
     txt1.setText('(A ⋂ C) ⋃ (B ⋂ C)')
 }
}
shuffle.on('down', function(){
shuffleit();
if(i<=4)
{
if(i == 0 && solution1() == true)
{ //is correct
  cross[i].setAttribute({visible:false});
  exclaim[i].setAttribute({visible:false});
  check[i].setAttribute({visible:true});
  yourScore = yourScore+1;
}
else if(i == 1 && solution2() == true)
{ //is correct
  cross[i].setAttribute({visible:false});
  exclaim[i].setAttribute({visible:false});
  check[i].setAttribute({visible:true});
  yourScore = yourScore+1;
}
else if(i == 2 && solution3() == true)
{ //is correct
  cross[i].setAttribute({visible:false});
  exclaim[i].setAttribute({visible:false});
  check[i].setAttribute({visible:true});
  yourScore = yourScore+1;
}
else if(i == 3 && solution4() == true)
{ //is correct
  cross[i].setAttribute({visible:false});
  exclaim[i].setAttribute({visible:false});
  check[i].setAttribute({visible:true});
  yourScore = yourScore+1;
}
else if(i == 4 && solution5() == true)
{ //is correct
  cross[i].setAttribute({visible:false});
  exclaim[i].setAttribute({visible:false});
  check[i].setAttribute({visible:true});
  yourScore = yourScore+1;
}
else
{
cross[i].setAttribute({visible:true});
yourWrong = yourWrong+1;
}
}
if(i==4)
{
bck.setAttribute({opacity:1});
scoreCard.setAttribute({visible:true});
missedCard.setAttribute({visible:true});
wrongCard.setAttribute({visible:true});
redo.setAttribute({visible:true});
}
else
{
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
i=i+1;
}
hide[i].setAttribute({visible:false});
whiteEverything();
});

function hideOk(){
    ok1.setAttribute({visible: false});
    ok2.setAttribute({visible: false});
    cross1.setAttribute({visible: false});
    cross2.setAttribute({visible: false});
}

test.on('down', function()
{
//  note.setAttribute({visible:false});
    if(i == 0 && solution1() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
    }
    else if(i == 1 && solution2() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
    }
    else if(i == 2 && solution3() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
    }
    else if(i == 3 && solution4() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
    }
    else if(i == 4 && solution5() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
    }
    else
    {
      cross[i].setAttribute({visible:true});
    }
});
redo.on('down', function(){
  //Hiding all blue circles
    hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
    exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    //note.setAttribute({visible:false});
    yourScore=0;
    yourMissed=0;
    yourWrong=0;
    yourTotal=0;
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
    i=0;
    bck.setAttribute({opacity:0});
    pointers.forEach(function(item){item.setAttribute({visible:false})});
    redo.setAttribute({visible:false});
    txt1.setText('A ⋃ B');
    ////////////////////////ALL CHANGES GO HERE/////////////
    //Ptm.moveTo([PtmList[0], 8], 200);
    ////////////////////////////////////////////////////////
    //alpha.setAttribute({visible:true});
    //beta.setAttribute({visible:true});
});
graph.unsuspendUpdate();
}
}
export default Boxes;
