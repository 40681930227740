<template>
  <div ref="library">
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              Explore <span class="header-etsy">&epsilon;</span>dliy!
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout :style="myStyle.layout"
              align-center
              justify-center
              row
              wrap
              :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        Edliy offers a library of more than 100 meticulously authored lessons on a variety of topics in Science, Engineering and Mathematics.
        Each lesson contains a brilliantly designed visually interactive workbook to provide students with a truly experiential learning environment.
        <br>
        <br>
      </v-flex>
    </v-layout>
    <v-layout align-center justify-center>
      <div class="header">
        <b>Let's Get Started</b>
      </div>
    </v-layout>
    <v-layout align-center justify-center>
      <div>
        <h5 style="color:black">
          (Choose a level to explore)
        </h5>
      </div>
    </v-layout>
    <br> <br>
    <DivideContent />
    <br>
    <br>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      row
      :class="{'headline mt-3 px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 mt-3 px-2': $vuetify.breakpoint.mdOnly, 'body-2 mt-3 px-1': $vuetify.breakpoint.smAndDown}"
    >
      <!--
      <v-flex
        v-for="cat in catArr"
        :key="cat.key"
        xs12
        sm12
        md12
        lg12
        xl12
      >
          <subtopic-card
            :headline="cat.title"
             description=""
            :img="cat.img"
            :path="cat.link"
          />
      </v-flex> -->
      <v-flex
        v-for="topic in topics"
        :key="topic.key"
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <subtopic-card
          :headline="topic.headline"
          :description="topic.description"
          :img="topic.img"
          :path="topic.path"
        />
      </v-flex>
    </v-layout>
    <br>
    <br>
    <v-layout :style="myStyle.layout"
              align-center
              justify-center
              row
              wrap
              :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        To see your currently active subscriptions, click here:
        <v-btn
          fab
          @click="closeModal"
        >
          <v-img
            src="/assets/renew.svg"
            aspect-ratio="1"
            max-width="30px"
            contain
          />
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import SubtopicCard from '../SubTopics.vue'
import DivideContent from '../Divider.vue'
import axios from 'axios';
export default {
    name: 'Dash2',
    components: {
    SubtopicCard,
    DivideContent
    },
    props: {
        myStyle: {
            type: Object,
            default: () => {}
        },},
    data () {
      return {
        topics: [
          {
            headline: "Foundations",
            description: "",
            img: "/assets/basics.svg",
            path: "/gradesFoundations?id=RGNYTWJhSmFsVkVOOTV0QXNOVlYxUT09"
          },
          {
            headline: "Intermediate",
            description: "",
            img: "/assets/intermediate.svg",
            path: "/gradesIntermediate?id=MHVvcThONUMwS1hNekhkOWlGdXR4dz09"
          },
          {
            headline: "Advanced",
            description: "",
            img: "/assets/advanced.svg",
            path: "/gradesAdvanced?id=Z1lWTmJ0OXJBWkNZR1orT1MwMWlsUT09"
          },
        ]
        }},
        methods: {
          closeModal(){
            this.$emit('close');
          }
        }
}
</script>
