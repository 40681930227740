import { render, staticRenderFns } from "./BuyTopics.vue?vue&type=template&id=b1ac03e0&scoped=true&"
import script from "./BuyTopics.vue?vue&type=script&lang=js&"
export * from "./BuyTopics.vue?vue&type=script&lang=js&"
import style0 from "./BuyTopics.vue?vue&type=style&index=0&id=b1ac03e0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1ac03e0",
  null
  
)

export default component.exports