<template>
  <div id="magic">
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              Welcome to <span class="header-etsy">&epsilon;</span>dliy!
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp, 'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Edliy is a visually interactive workbook &mdash; designed to help students learn science and math concepts through visually interactive lessons, activities and assessments.
          <br>
          <br>
        </div>
        <v-layout
          justify-center
          align-center
          class="mb-3 pa-2"
        >
          <v-flex
            xl4
            sm4
            md4
            lg4
            xl4
          >
            <v-layout justify-center align-center>
              <v-img
                src="/assets/eggOne.svg"
                max-height="40px"
                max-width="40px"
                contain
              />
            </v-layout>
            <v-layout class="mt-1" justify-center align-center>
              <h5> Build Concepts </h5>
            </v-layout>
          </v-flex>
          <v-flex
            xl4
            sm4
            md4
            lg4
            xl4
          >
            <v-layout justify-center align-center>
              <v-img
                src="/assets/eggTwo.svg"
                max-height="40px"
                max-width="40px"
                contain
              />
            </v-layout>
            <v-layout class="mt-1" justify-center align-center>
              <h5> Practice Problems </h5>
            </v-layout>
          </v-flex>
          <v-flex
            xl4
            sm4
            md4
            lg4
            xl4
          >
            <v-layout justify-center align-center>
              <v-img
                src="/assets/eggThree.svg"
                max-height="40px"
                max-width="40px"
                contain
              />
            </v-layout>
            <v-layout class="mt-1" justify-center align-center>
              <h5> Test Knowledge </h5>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-layout justify-center align-center>
          <vc-calendar :attributes="attrs" />
        </v-layout>
      </v-flex>
    </v-layout>
    <div style="margin-top:5px" />
    <br>
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Edliy offers comprehensive learning aids & activities that assist you in every step of your learning journey.
          <br>
          <!--  Through visual interactivity, Edliy creates a unique learning experience that combines visual aids with an interactive interface and engaging storytelling.
      <!--  Famous author and storyteller Robert McKee once said &mdash; "Storytelling is the most powerful way to put ideas into the world." -->

        <!--  Our visually interactive apps empower students to learn from virtual experiments, test different hypotheses, and gain a 360<sup>o</sup> view of the concepts in Science, Engineering, Technology and Mathematics (STEM). -->
        </div>
      </v-flex>
    </v-layout>
    <DivideContent />
    <div style="margin-top: 20px" />
    <v-layout
      justify-center
      align-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div class="font-weight-medium"
             :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Build & Master Concepts</span>
        </div>
        <div style="margin-top:5px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp, 'subtitle-1 px-2': $vuetify.breakpoint.mdOnly,'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Edliy offers a hands-on approach to learning concepts in science and math. Hands-on learning is not only a lot more fun, but is also proven to improve engagement and retention.
          <br>
          <a class="btnLeft mt-3 mb-4" @click="closeModal"> Get started!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-1.webm" type="video/webm">
                <source src="assets/webm-1.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox1a" class="edliy-box-abt" style="jsxgraph" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <!-- ========== Slide 2 ========== -->
    <v-layout
      justify-center
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-1': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1': $vuetify.breakpoint.mdAndUp,'headline': $vuetify.breakpoint.mdOnly, 'title': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald">Improve Problem Solving Skills</span>
        </div>
        <div style="margin-top: 5px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline': $vuetify.breakpoint.lgAndUp, 'subtitle-1': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          Practice makes us perfect. Our visually interactive workbooks let you get as much practice as you need to master a concept.
          Plus you get real-time feedback &mdash; creating opportunities for you to learn and improve.
          <br>
          <a class="btnLeft mt-3 mb-4" @click="closeModal"> Try it yourself!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-2.webm" type="video/webm">
                <source src="assets/webm-2.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox2a" class="edliy-box-abt" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald">
            Test Your Knowledge
          </span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          Say goodbye to exam anxiety. With our visually interactive assessment modules, students test their knowledge and get ready for exams in a fun and engaging way.
          <br>
          <a class="btnLeft mt-3 mb-4" @click="closeModal"> Try it now!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-5.webm" type="video/webm">
                <source src="assets/webm-5.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox1" class="edliy-box-abt" />
            </v-layout>
          </slide>
        </carousel>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
      reverse="$vuetify.breakpoint.mdAndUp"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1': $vuetify.breakpoint.mdAndUp,'headline': $vuetify.breakpoint.mdOnly, 'title': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Learn the Way You Want </span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline': $vuetify.breakpoint.lgAndUp, 'subtitle-1': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
        >
          Every student is different and learns at a different pace. At Edliy, all of our workbooks are designed to be self-paced &mdash; So, students can learn at a pace that works best for them.
          <br>
          <a class="btnLeft mt-3 mb-4" @click="closeModal"> Learn more!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <carousel
          class="mx-auto"
          :scroll-per-page="true"
          pagination-position="bottom"
          pagination-color="#FFD600"
          pagination-active-color="#FFC200"
          :pagination-size="15"
          :per-page="1"
          :pagination-padding="4"
          :mouse-drag="true"
          :touch-drag="true"
        >
          <slide>
            <h5 style="text-align:center">
              Swipe left to try it yourself.
            </h5>
            <v-layout justify-center>
              <video autoplay
                     loop
                     muted
                     playsinline
                     class="gif"
              >
                <source src="assets/webm-4.webm" type="video/webm">
                <source src="assets/webm-4.mp4" type="video/mp4">
              </video>
            </v-layout>
          </slide>
          <slide>
            <h5 style="text-align:center">
              Swipe right to watch a video blurb.
            </h5>
            <v-layout justify-center>
              <div id="jxgbox5a" class="edliy-box-abt" />
            </v-layout>
          </slide>
        </carousel>
      </v-flex>
    </v-layout>
  </div>
  </div>
</template>
<script>
import Boxes from '../Home/Boxes.js'
import DivideContent from '../Divider.vue'
export default {
    name: 'Dash5',
    components: {
    DivideContent
    },
    props: {
      myStyle: {
        type: Object,
          default: () => {}
        }
        },
        data () {
          return {
            tab:'tab-1',
            attrs: [
              {
                key: 'today',
                highlight: 'red',
                dates: new Date(),
              }],
          }
        },
    methods:{
     closeModal(){
       this.$emit('close');
     }
     },
}
</script>
