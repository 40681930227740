<template>
  <v-container align-content-center class="main">
    <v-tabs
      v-model="tab"
      centered
      dark
      icons-and-text
    >
      <v-tabs-slider color="red" />

      <v-tab class="pa-1" href="#tab-1">
        Dashboard
        <v-btn icon
               small
               color="lighten-2"
               dark
        >
          <v-img
            src="/assets/dash.svg"
            aspect-ratio="1"
            max-width="45px"
            contain
          />
        </v-btn>
      </v-tab>
      <v-tab class="pa-1" href="#tab-2">
        Getting Started
        <v-btn icon
               small
               color="lighten-2"
               dark
        >
          <v-img
            src="/assets/getStarted.svg"
            aspect-ratio="1"
            max-width="45px"
            contain
          />
        </v-btn>
      </v-tab>
      <v-tab class="pa-1" href="#tab-3">
        Your Subscription
        <v-btn icon
               small
               color="lighten-2"
               dark
        >
          <v-img
            src="/assets/subscribe.svg"
            aspect-ratio="1"
            max-width="45px"
            contain
          />
        </v-btn>
      </v-tab>
      <v-tab class="pa-1" href="#tab-4">
        About Edliy
        <v-btn icon
               small
               color="lighten-2"
               dark
        >
          <v-img
            src="/assets/edliyE.svg"
            aspect-ratio="1"
            max-width="45px"
            contain
          />
        </v-btn>
      </v-tab>

      <v-tab-item
        id="tab-1"
        value="tab-1"
      >
        <featured-content :my-style="style" @close="changeTab" />
      </v-tab-item>

      <v-tab-item
        id="tab-2"
        value="tab-2"
      >
        <topic-content :my-style="style"@close="moveTab" />
      </v-tab-item>

      <v-tab-item
        id="tab-3"
        value="tab-3"
      >
        <your-content :my-style="style" @close="changeTab" />
      </v-tab-item>

      <v-tab-item
        id="tab-4"
        value="tab-4"
      >
        <edliy-content :my-style="style" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import FeaturedContent from './dash-5.vue'
import EdliyContent from './dash-1.vue'
import TopicContent from './dash-2.vue'
import YourContent from './dash-3.vue'
import DivideContent from '../Divider.vue'
import Boxes from './Boxes.js'
export default {
    name: 'DashBoard',
    components: {
    EdliyContent,
    TopicContent,
    YourContent,
    FeaturedContent,
    DivideContent
    },
    data () {
      return {
        style: {layout: "margin: 1%"},
        tab:'tab-1'
             }
           },
    watch:{
             $route (to, from){
                if(to.hash == "#enrolled"){
                  setTimeout(function() {
                   document.getElementById('enrolled').scrollIntoView()
                 }, 100);
                }
                if(to.hash == "#library"){
                  setTimeout(function() {
                   document.getElementById('library').scrollIntoView()
                 }, 100);
                }
             }
           },
    mounted() {
             Boxes.box1a();
             Boxes.box2a();
             Boxes.box5a();
             Boxes.box7a();
           },
    created ()  {
    this.$store.commit('navigation/resetState');
    this.$store.commit('navigation/changeTitle', 'My Dashboard');
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceFind', false);
    this.$store.commit('navigation/userDashboard', true);
    },
    methods : {
    changeTab()
               {
                 this.tab='tab-2'
                 window.scrollTo(0, -250);
               },
    moveTab()
    {
      this.tab='tab-3'
      window.scrollTo(0, -250);
    }
  },
  /* mounted () {
     console.log(showFind);
   },*/
   metaInfo() {
   return{ title: 'Your Dashboard',
           titleTemplate: '%s | Learn interactively',
           meta: [ {vmid:'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1'},
                   {vmid:'description', name: 'description', content: 'This is your dashboard.'}
                 ],
         }
    }
    }
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
#jxgbox4a input {border-style:solid;border-radius:4px;background-color:#FEBD49}

.about-img {
    margin-left: 100px;
}
.reload{
position: relative;
top: -50px;
left: 30px;
}
.main{
max-width:1800px;
width:100%;
}
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;
}
.btnhead{
background-color: #4CAF50; /* Green */
border-style: solid;
border: 0px;
border-radius: 3.5px;
color: white;
padding: 5px 15px 5px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-family: Oswald;
@include respond-to(less-smallest-screens) {font-size: 12px;}
@include respond-to(smallest-screens) {font-size: 14px;}
@include respond-to(small-screens) {font-size: 16px;}
@include respond-to(medium-screens) {font-size: 20px;}
@include respond-to(large-screens) {font-size: 25px;}
@include respond-to(largest-screens) {font-size: 30px;}
}
.btntitle{
border-style: solid;
border: 0px;
border-radius: 3.5px;
color: black;
padding: 5px 15px 5px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-family: Oswald;
font-size :32px;
}
@include respond-to(less-smallest-screens) {
.size{
font-size:28px;
//font-weight: bold;
font-family:Oswald;
}
}
@include respond-to(smallest-screens) {
.size{
font-size:29px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(small-screens) {
.size{
font-size:40px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(medium-screens) {
.size{
font-size:62px;
font-weight:light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(large-screens) {
.size{
font-size:56px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(largest-screens) {
.size{
font-size:62px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
.imgL{
@include respond-to(less-smallest-screens) {width:32%;}
@include respond-to(smallest-screens) {width:37%;}
@include respond-to(small-screens) {width: 60%;}
@include respond-to(medium-screens) {width: 67%;}
@include respond-to(large-screens) {width: 84%;}
@include respond-to(largest-screens) {width: 100%;}
}
.imgK {
margin-left:auto;
margin-right:auto;
margin-top:auto;
margin-bottom:auto;
left:0;
@include respond-to(less-smallest-screens) {width:50vw; height:50vw;}
@include respond-to(smallest-screens) {width:50vw; height:50vw;}
@include respond-to(small-screens) {width:40vw; height:40vw;}
@include respond-to(medium-screens) {width:40vw; height:40vw;}
@include respond-to(large-screens) {width:24vw; height:24vw;}
@include respond-to(largest-screens) {width:24vw; height:24vw;}
}
.jsxbox {
@include respond-to(less-smallest-screens) {width:12vw; height:12vw;}
@include respond-to(smallest-screens) {width:12vw; height:12vw;}
@include respond-to(small-screens) {width:12vw; height:12vw;}
@include respond-to(medium-screens) {width:24vw; height:24vw;}
@include respond-to(large-screens) {width:24vw; height:24vw;}
@include respond-to(largest-screens) {width:24vw; height:24vw;}
}
.rounded-card{
    border-radius:10px;
    width:100%;
}
</style>
