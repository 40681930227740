<template>
  <div ref="enrolled">
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              Your Subscriptions @ <span class="header-etsy">&epsilon;</span>dliy
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout :style="myStyle.layout"
              align-center
              justify-center
              row
              wrap
              :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        Once you subscribe to a course, the course will appear below. You currently have {{ this.activeArr.length }} active subscriptions and {{ this.expArr.length }} inactive subscriptions.
        <br>
        <br>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      row
      :class="{'headline mt-3 px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 mt-3 px-2': $vuetify.breakpoint.mdOnly, 'body-2 mt-3 px-1': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        v-for="course in activeArr"
        :key="course.key"
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <!--:headline1="course.category_title" -->
        <span @click="redirectLink(course.link, course.id)">
          <subtopic-card
            :headline1="course.description"
            :headline2="course.title"
            :img="'/assets/Mathematics' + '-' + firstEl(course.title) + '.svg'"
            path=""
          />
        </span>
      </v-flex>
      <v-flex
        v-if="activeArr.length==0"
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <empty-card
          headline1="Future"
          headline2="Subscription"
          description=""
          img="/assets/books.svg"
          path=""
        />
      </v-flex>
      <v-flex
        v-if="activeArr.length==0 || activeArr.length==1"
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <empty-card
          headline1="Future"
          headline2="Subscription"
          description=""
          img="/assets/books.svg"
          path=""
        />
      </v-flex>
      <v-flex
        v-if="activeArr.length==0 || activeArr.length==1 || activeArr.length==2"
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <empty-card
          headline1="Future"
          headline2="Subscription"
          description=""
          img="/assets/books.svg"
          path=""
        />
      </v-flex>
    </v-layout>
    <br>
    <v-layout :style="myStyle.layout"
              align-center
              justify-center
              row
              wrap
              :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        To subscribe to a course, start here:
        <v-btn
          fab
          @click="closeModal"
        >
          <v-img
            src="/assets/launch.svg"
            aspect-ratio="1"
            max-width="25px"
            contain
          />
        </v-btn>
      </v-flex>
    </v-layout>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SubtopicCard from '../BuyTopics.vue'
import EmptyCard from '../EmptyTopics.vue'
import axios from 'axios';
export default {
    name: 'Dash3',
    components: {
    SubtopicCard,
    EmptyCard
    },
    props: {
        myStyle: {
            type: Object,
            default: () => {}
        },},
    data () {
      return {
        coursesArr: [],
        idArr:[],
        activeArr:[],
        expArr:[],
        endDate:null,
        catArr: [] }
           },
    async mounted () {
        //console.log("userId::", localStorage.getItem('userId'))
        const response = await axios.get(process.env.VUE_APP_API+'user/'+localStorage.getItem('userId')+'/mycourses', { headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
        if(response.data.success)
        {

            this.coursesArr = response.data.data.courses;
            let courses = response.data.data.courses;
            let  ids =[];
            let  exArr=[];
            let  actArr=[];
            courses.forEach(function(item){
              ids.push(item.id);
            })
            ids.forEach(async (item, i) => {
              let url = process.env.VUE_APP_API+'course/'+item+'/chapters/'+localStorage.getItem('userId');
              const responsed = await axios.get(url, {headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
              const today = new Date();
              if(responsed.data.success)
              {
                let grades = responsed.data.data.course;
                console.log("success", grades);
                let endDate = new Date(grades.ends_at);
                console.log("endDate::success", endDate);
                if(today.getTime() > endDate.getTime())
                  {
                    exArr.push(grades);
                  }
                else
                  {
                    actArr.push(grades);
                  }
                console.log("inactive::", exArr);
                console.log("active::", actArr);
                }
            });
            this.idArr = ids;
            this.activeArr=actArr;
            this.expArr=exArr;
            console.log("success::", this.idArr);
        }
        else
        {
             this.$notify({
                      group: 'notify',
                      type: 'error',
                      title: 'Error!!',
                      text: 'Something went wrong!!'
             });
        }
        console.log("You have subscribed to "+ this.coursesArr.length + " courses")
     },
     methods: {
       closeModal(){
         this.$emit('close');
       },
       redirectLink(link, id){
         this.$router.push(link+"?grade="+id)
       },
       firstEl(row)
       {
         return (row !== null)? row.split(" ")[0] : '';
       },
       addCourses(options){
          const el = this.$el.getElementsByClassName('subjects-cls')[0];

         if (el) {
           el.scrollIntoView(options);
         }
       }
   	},
}
</script>
